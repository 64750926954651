import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getDocumentTypeInfo } from '../../API/document';
import { InActiveDocument, NoPermission } from '../../components/General';
import ConfirmationModal, {
    IAcceptedType,
} from '../../components/confirmation-modal/ConfirmationModal';
import DocumentProvider, { useDocumentContext } from '../../contexts/document-provider';
import { removeFromLocalStorage, setLocalStorage } from '../../utils/local-storage';
import DocumentInfo from '../../widgets/document-info/DocumentInfo';
import NewDocDetail from '../../widgets/new-doc-detail/NewDocDetail';
import './CreateDocument.scss';

// eslint-disable-next-line max-lines-per-function
const CreateDocument: React.FC = () => {
    const {
        docInfo,
        docTypeInfo,
        documentTypeId,
        documentId,
        selectedDocVersion,
        activeDocVersionId,
        docVersions,
        docTitleError,
        docDescError,
        docEffectiveFromError,
        docEffectiveError,
        docSunsetError,
        setSelectedDocVersion,
        setDocInfo,
        fetchDocumentVersion,
        canViewCreateDocument,
        accessibleByCurrentUser,
        publishOnceApproved,
        isSunsetEnabled,
        activeVersionOwnerInfo,
        checkInVersionOwner,
        activeVersionApprovalDeadline,
        setActiveVersionApprovalDeadline,
        isEndDateAsked,
        relationArr,
        setRelationArr,
        latestrelationArr,
        setlatestrelationArr,
        isFieldsDataSaved,
    } = useDocumentContext();
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const docNumberValue = queryParams.get('doc_id');
    const fetchDocumentTypeInfo = async (val: number) => {
        if (val) {
            const res = await getDocumentTypeInfo(Number(val));
            if (res?.docTypeConfig?.isDCO) {
                setLocalStorage('isDCO', 'true');
            } else {
                setLocalStorage('isDCO', 'false');
            }
        }
    };
    useEffect(() => {
        if (docNumberValue) {
            fetchDocumentTypeInfo(Number(docNumberValue));
        }
        setLocalStorage('isDCO', 'false');
        return () => {
            removeFromLocalStorage('isDCO');
        };
    }, [docNumberValue]);
    const history = useHistory();
    const [showLeaveModal, setLeaveModal] = useState(false);
    const destinationUrlRef = useRef('');
    const leaveButtonClicked = useRef('');
    const [hasBeenSaved, setHasBeenSaved] = useState(false);
    useEffect(() => {
        if (isFieldsDataSaved) {
            setHasBeenSaved(true);
        }
    }, [isFieldsDataSaved]);
    useEffect(() => {
        const unblock = history.block((location) => {
            destinationUrlRef.current = location.pathname + location.search;
            const currentURL = window.location.pathname + window.location.search;
            if (leaveButtonClicked.current === 'true') {
                return undefined;
            }
            if (
                `/app${destinationUrlRef.current}` !== currentURL &&
                !hasBeenSaved &&
                !destinationUrlRef.current.includes('/create-document')
            ) {
                setLeaveModal(true);
                return false;
            }
            return undefined;
        });
        return () => {
            unblock();
        };
    }, [history, hasBeenSaved]);
    const [leaveModalData, setLeaveModalData] = useState({
        type: 'LEAVE',
        onConfirm: () => {
            setLeaveModal(false);
            leaveButtonClicked.current = 'true';
            history.push(destinationUrlRef.current);
            return undefined;
        },
    });
    return (
        <>
            {documentTypeId.toString() !== process.env.REACT_APP_INACTIVE_DOCUMENT_TYPE_ID ||
            docInfo.docNumber !== 'Not Assigned' ? (
                <>
                    {canViewCreateDocument ||
                    accessibleByCurrentUser ||
                    (docTypeInfo.anyoneCanEditDraft &&
                        (docInfo.docState === 'DRAFT' || docInfo.docState === 'REJECTED')) ? (
                        <>
                            {' '}
                            <DocumentInfo
                                pageType={
                                    accessibleByCurrentUser && docInfo.docState === 'UNDER_REVIEW'
                                        ? 'EDIT_DOCUMENT'
                                        : 'CREATE_DOCUMENT'
                                }
                                docInfo={docInfo}
                                documentId={documentId}
                                activeDocVersion={selectedDocVersion}
                                docVersions={docVersions}
                                setActiveDocVersion={setSelectedDocVersion}
                                setDocInfo={setDocInfo}
                                onFavoriteClickHAndler={fetchDocumentVersion}
                                titleError={docTitleError}
                                descError={docDescError}
                                effectiveFromError={docEffectiveFromError}
                                effectivePeriodError={docEffectiveError}
                                sunsetPeriodError={docSunsetError}
                                docTypeInfo={docTypeInfo}
                                publishOnceApproved={publishOnceApproved}
                                isSunsetEnabled={isSunsetEnabled}
                                latestDocVersionId={activeDocVersionId}
                                activeVersionOwnerInfo={activeVersionOwnerInfo}
                                checkInVersionOwner={checkInVersionOwner}
                                activeVersionApprovalDeadline={activeVersionApprovalDeadline}
                                setActiveVersionApprovalDeadline={setActiveVersionApprovalDeadline}
                                isEndDateAsked={isEndDateAsked}
                                relationArr={relationArr}
                                setRelationArr={setRelationArr}
                                latestrelationArr={latestrelationArr}
                                setlatestrelationArr={setlatestrelationArr}
                            />
                            <NewDocDetail docTypeInfo={docTypeInfo} />
                        </>
                    ) : (
                        <NoPermission />
                    )}
                </>
            ) : (
                <InActiveDocument />
            )}
            {showLeaveModal && (
                <ConfirmationModal
                    type={leaveModalData.type as IAcceptedType}
                    isModalOpen={showLeaveModal}
                    onModalClose={setLeaveModal}
                    onConfirm={leaveModalData.onConfirm}
                />
            )}
        </>
    );
};

const CreateDocumentPage: React.FC = () => (
    <div className="new-document">
        <DocumentProvider>
            <CreateDocument />
        </DocumentProvider>
    </div>
);

export default CreateDocumentPage;
