/* eslint-disable max-lines-per-function */
/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { getMilliseconds } from 'date-fns';
import { useTransition, animated } from 'react-spring';

import './SearchCard.scss';
import { ISearch } from '../../interfaces/dashboard';
import CONSTANTS from '../../constants';
import { returnformatDateOnly } from '../../utils/date';
import { DownloadAttachment } from '../attachments/view/ViewAttachments';
import { FavoriteComponent } from '../General';
import CopyText from '../CopyText';
import { downloadAttachment } from '../../API/attachments';
import { SkeletonText } from '../Skeleton';
import useWindowSize from '../../hooks/use-window-size';
import STATIC_CONTENT from '../../constants/StaticContent';

const { SEARCH } = STATIC_CONTENT;

// const NO_OF_ITEMS_TO_SHOW = 0;

// interface TextHighlightersProps {
//     highlightedTexts: IHighlighted[];
// }

// // eslint-disable-next-line max-lines-per-function
// const TextHighlighters: React.FC<TextHighlightersProps> = ({ highlightedTexts }) => {
//     const [highlighters, setHighlighters] = useState(
//         highlightedTexts.slice(0, NO_OF_ITEMS_TO_SHOW),
//     );

//     const onClick = () => {
//         if (highlighters.length === NO_OF_ITEMS_TO_SHOW) {
//             setHighlighters(highlightedTexts);
//         } else {
//             setHighlighters(highlightedTexts.slice(0, NO_OF_ITEMS_TO_SHOW));
//         }
//     };

//     return (
//         <>
//             {highlighters.map((highlighted, index) => (
//                 // eslint-disable-next-line react/no-array-index-key
//                 <div className="search-card__highlighter" key={index}>
//                     <div className="card-info-item">
//                         <label htmlFor="version">Match Found in</label>
//                         <div>
//                             <h4
//                                 onClick={() => {
//                                     if (highlighted.id) {
//                                         downloadAttachment(highlighted.id, highlighted.foundIn);
//                                     }
//                                 }}
//                                 role="presentation"
//                             >
//                                 {highlighted.foundIn}
//                             </h4>
//                             {highlighted.id ? (
//                                 <DownloadAttachment
//                                     attachmentId={highlighted.id}
//                                     fileName={highlighted.foundIn}
//                                 />
//                             ) : null}
//                         </div>
//                         :
//                         <p
//                             className="highlighter"
//                             dangerouslySetInnerHTML={{ __html: highlighted.text }}
//                         />
//                     </div>
//                 </div>
//             ))}
//             {highlightedTexts.length > NO_OF_ITEMS_TO_SHOW && (
//                 <div className="highlighter-btn">
//                     <button type="button" className="link" onClick={() => onClick()}>
//                         {highlighters.length > NO_OF_ITEMS_TO_SHOW ? 'Show less' : 'Show more'}
//                     </button>
//                 </div>
//             )}
//         </>
//     );
// };

const WIDTH_OF_SIDEBAR_AND_MARGINS = 450;

interface Props {
    searchDocument: ISearch;
    favoriteDocIds: number[];
    fetchFavoriteDocIds: () => void;
    docTypeColor?: string;
}

// eslint-disable-next-line max-lines-per-function
const SearchCard: React.FC<Props> = ({
    searchDocument,
    favoriteDocIds,
    fetchFavoriteDocIds,
    docTypeColor,
}) => {
    // const history = useHistory();

    const windowSize = useWindowSize();

    // Defined but never used

    // const onTitleClick = () => {
    //     history.push({
    //         pathname: `${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`,
    //         search: `?doc_id=${searchDocument.docId}&prev_page_type=SEARCH`,
    //     });
    // };

    const [showMore, setShowMore] = useState(false);
    const rightPartWidth = document.getElementById('header-right-part')?.offsetWidth as number;
    const transition = useTransition(showMore, {
        from: { x: 0, y: -20, opacity: 0 },
        enter: { x: 0, y: 0, opacity: 1 },
        leave: { x: 0, y: -30, opacity: 0 },
    });

    /* const x = Array.from(document.getElementsByClassName('copy-text') as HTMLCollectionOf<HTMLElement>)
    // eslint-disable-next-line no-plusplus
    for( let i = 0; i < x.length; i++ ) {
        if(x[i] !== null){
            x[i].style.backgroundColor=docColors[(randomNumberInRange(0, docColors.length - 1)).arrayIndex];
        }
    } */
    return (
        <div className="search-card">
            <div className="search-card__header" id="search-card__header">
                <div className="header-title">
                    <Link
                        className="link-style"
                        style={{
                            textDecoration: 'none',
                            maxWidth: `${
                                windowSize.width - rightPartWidth - WIDTH_OF_SIDEBAR_AND_MARGINS
                            }px`,
                            fontSize: '18px',
                        }}
                        to={{
                            pathname: `/${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`,
                            search: `?doc_id=${searchDocument.docId}&prev_page_type=SEARCH`,
                        }}
                        role="presentation"
                    >
                        <SkeletonText text={searchDocument.title} width={60} />
                    </Link>
                    <div style={{ backgroundColor: docTypeColor }} className="disabled">
                        {searchDocument.documentTypeName}
                    </div>
                    {/* {searchDocument.status && (
                            <span
                                className={`status-pill ${searchDocument.status.toLocaleLowerCase()}`}
                            >
                                {searchDocument.status === 'NEW' ? (
                                    <Skeleton width={80} />
                                ) : (
                                    CONSTANTS.DOCUMENT_STATUS[searchDocument.status]
                                )}
                            </span>
                        )} */}
                </div>
                <div className="header-right-part" id="header-right-part">
                    <div className="fav-copy">
                        <CopyText
                            text={searchDocument.docNumber}
                            docTypeBackgroundColor={docTypeColor}
                        />
                    </div>

                    <FavoriteComponent
                        docId={searchDocument.docId}
                        isActive={!!favoriteDocIds.includes(searchDocument.docId)}
                        onClickHandler={fetchFavoriteDocIds}
                    />
                </div>
            </div>
            <div className="search-card__description">
                <p
                    className="test"
                    style={{
                        maxWidth: `${windowSize.width - WIDTH_OF_SIDEBAR_AND_MARGINS}px`,
                    }}
                >
                    <SkeletonText text={searchDocument.description} width={60} />
                </p>
            </div>
            <div className="search-card__info">
                <div className="card-info-item">
                    <label htmlFor="version">Version</label>
                    <h4>
                        <SkeletonText text={searchDocument.version} width={60} />
                    </h4>
                </div>
                <div className="card-info-item">
                    {getMilliseconds(searchDocument.createdDate) === 1 ? (
                        <>
                            <label htmlFor="approvedOn">Published on</label>
                            <h4>
                                <Skeleton width={100} />
                            </h4>
                        </>
                    ) : (
                        <>
                            <label htmlFor="approvedOn">Published on</label>
                            <h4>
                                <SkeletonText
                                    text={returnformatDateOnly(searchDocument.createdDate)}
                                    width={60}
                                />
                            </h4>
                        </>
                    )}
                </div>
                <div className="card-info-item">
                    <label htmlFor="author">Author</label>
                    <h4>
                        <SkeletonText text={searchDocument.authorName} width={60} />
                    </h4>
                </div>
                {searchDocument.highlighted.length > 0 && (
                    <div className="highlighter-btn">
                        <button
                            type="button"
                            className="link"
                            onClick={() => setShowMore(!showMore)}
                        >
                            {showMore ? SEARCH.BUTTON.SEE_LESS : SEARCH.BUTTON.SEE_MORE}
                        </button>
                    </div>
                )}
            </div>
            {showMore &&
                transition((style, item) =>
                    item ? (
                        <animated.div style={style} className="item">
                            {searchDocument.highlighted.map((highlighted, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <div className="search-card__highlighter" key={index}>
                                    <div className="card-info-item">
                                        <label htmlFor="version">Match Found in</label>
                                        <div
                                            className={`${
                                                highlighted.id ? 'attachment__detail' : ''
                                            }`}
                                        >
                                            <h4
                                                onClick={() => {
                                                    if (highlighted.id) {
                                                        downloadAttachment(
                                                            highlighted.id,
                                                            highlighted.foundIn,
                                                        );
                                                    }
                                                }}
                                                role="presentation"
                                            >
                                                {highlighted.foundIn}
                                            </h4>
                                            {highlighted.id ? (
                                                <DownloadAttachment
                                                    attachmentId={highlighted.id}
                                                    fileName={highlighted.foundIn}
                                                />
                                            ) : null}
                                        </div>
                                        :
                                        <p
                                            className="highlighter"
                                            dangerouslySetInnerHTML={{ __html: highlighted.text }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </animated.div>
                    ) : (
                        ''
                    ),
                )}
        </div>
    );
};

export default SearchCard;
